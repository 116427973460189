<template>
  <div class="page_container">
    <div class="list_card">
      <div class="push_card">
        <div class="left">
          <div class="c_today">
            <span>今天</span>
            <div>星期{{ datetime.day }}</div>
          </div>
          <div class="number_today">
            <span>{{ datetime.month }}</span>
            <span>月</span>
            <span>{{ datetime.date }}</span>
            <span>日</span>
          </div>
          <div class="arab_today">
            <span>{{ datetime.hour }}:{{ datetime.minute }}:{{ datetime.second }}</span>
          </div>
        </div>
        <button @click="refreshList"><i class="fa fa-refresh" ref="refreshIcon"></i>刷新</button>
      </div>

      <div class="fast_card">
        <ul class="tabs">
          <li ref="fastAll" @click="getFastList()">全部</li>
          <li ref="fastNotAll" @click="getFastList(1)">快讯精选</li>
        </ul>
        <ul class="fast_item">
          <li v-for="(item, index ) in fastList" :key="index">
            <a @click="skipDetails(item.atUuid)" :class="{title:true,isSelected: item.isSelected === 1 }">{{
                item.title
              }}</a>
            <p @click="skipDetails(item.atUuid)" class="content" v-html="item.introduction"></p>
            <div class="tools_tip">
              <div>
                <span class="time">{{ item.showTime }}</span>
                <span class="source" v-if="item.source">来源: {{ item.source }}</span>
              </div>
              <div>
                <span><i class="fa fa-eye"></i>{{ item.scanCnt == null ? 0 : item.scanCnt }}</span>
                <span class="thumbs-up"><i
                    class="fa fa-thumbs-o-up"></i>{{ item.praiseCnt == null ? 0 : item.praiseCnt }}</span>
                <span class="thumbs-up" @click="sharePoster(item.atUuid)"><i
                    class="fa fa-share-alt"></i>{{ item.shareCnt == null ? 0 : item.shareCnt }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="aside_area" ref="fastAside">
      <div>
        <hot-article></hot-article>
      </div>
    </div>
    <div class="generateImages" v-if="showPoster">
      <div>
        <div class="html2canvas" id="html2canvas" ref="html2canvas">
          <div class="topCard">
            <img class="bgImg" src="../../assets/image/cardBanner.png">
            <div class="title">
              <img style="width: 157px;height:32px;object-fit:cover" src="../../assets/image/logoCards.png">
            </div>
            <div class="link-box">www.metaversehub.net</div>
          </div>
          <div class="centerCard">
            <div class="date">
              <span class="title">元宇宙快讯</span>
              <div class="date-hour">
                <span>{{ datetime.month }}月{{ datetime.date }}日</span>
                <span>{{ datetime.hour }}:{{ datetime.minute }}</span>
                <span>星期{{ datetime.day }}</span>
              </div>
            </div>
            <div class="title">{{ poster.title }}</div>
            <p v-html="poster.context" class="content"></p>
            <!--            <div class="statement">免责声明:本文不代表元宇宙之心立场，且不构成投资建议，请谨慎对待</div>-->
          </div>
          <div class="bottomCard">
            <div class="source">
              <div class="tip"><span class="circle"></span> 元宇宙第一入口，引领新科技</div>
              <!-- <div class="split"></div> -->
              <div class="laiyue">扫码关注元宇宙之心公众号</div>
              <!-- · -->
              <div class="laiyue">获取更多行业资讯<span class="circle-small">·</span>快讯</div>
              <img src="../../assets/image/wechathong.jpg">
            </div>

          </div>
        </div>
        <button class="closeButton" @click="showPoster = false">
          <img src="../../assets/image/close.png"/>
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import HotArticle from "../../components/HotArticle/HotArticle";
import html2canvas from 'html2canvas';

let timer;

export default {
  name: "index",
  components: {HotArticle},
  data: () => ({
    timer: '',
    datetime: {
      day: '',
      month: '',
      date: '',
      hour: '',
      minute: '',
      second: ''
    },
    fastList: [],
    currentPage: 1,
    currentPageSize: 10,
    totalPage: 999,
    isSelected: '',

    showPoster: false,
    poster: {}
  }),
  created() {
    this.$store.commit('setMenuActive', 1)
    this.getFastList()
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
    // this.$refs.fastAside.style.height = 'calc(100vh - 100px)'
    // document.documentElement.scrollTop = 2

    let today = new Date()
    var a = new Array("日", "一", "二", "三", "四", "五", "六");
    var week = today.getDay();
    this.datetime.day = a[week]
    this.datetime.month = this.dayjs().format('MM')
    this.datetime.date = this.dayjs().format('DD')
    this.datetime.hour = this.dayjs().format('HH')
    this.datetime.minute = this.dayjs().format('mm')
    this.datetime.second = this.dayjs().format('ss')
    /* 每秒定时刷新 */
    this.timer = setInterval(() => {
      this.datetime.month = this.dayjs().format('MM')
      this.datetime.date = this.dayjs().format('DD')
      this.datetime.hour = this.dayjs().format('HH')
      this.datetime.minute = this.dayjs().format('mm')
      this.datetime.second = this.dayjs().format('ss')
    }, 1000)
  },
  methods: {
    async sharePoster(uuid) {
      let that = this
      that.showPoster = true
      await this.axios.get('/article/detail/' + uuid).then(res => {
        this.poster = res.data
      })
      this.$nextTick(() => {
        that.saveImage('html2canvas', 'poster')
      })
    },
    handleScroll() {
      var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      // 修改侧边信息
      let scrollBottom = scrollHeight - scrollTop - windowHeight;
      if (scrollBottom <= 281 && scrollBottom >= 200) {
        this.$refs.fastAside.style.height = 'calc(100vh - 100px - 60px)'
      } else if (scrollBottom < 200 && scrollBottom >= 100) {
        this.$refs.fastAside.style.height = 'calc(100vh - 100px - 160px)'
      } else if (scrollBottom < 100) {
        this.$refs.fastAside.style.height = 'calc(100vh - 100px - 263px)'
      } else {
        this.$refs.fastAside.style.height = 'calc(100vh - 100px)'
      }
      if (scrollTop == 0) {
        this.$refs.fastAside.style.height = 'calc(100vh - 100px)'
      }

      if (scrollTop + windowHeight >= scrollHeight - 400) {
        let params = {
          currentPageSize: 10,
          currentPage: this.currentPage + 1,
          isSelected: this.isSelected
        }
        this.throttle(params)
      }
    },
    getFastList(...isSelected) {
      let params = {
        currentPage: 1,
        currentPageSize: 10,
        isSelected: ''
      }
      this.$nextTick(() => {
        if (isSelected == 1) {
          params.isSelected = 1
          this.$refs.fastAll.classList.remove('active')
          this.$refs.fastNotAll.classList.add('active')
        } else {
          this.$refs.fastAll.classList.add('active')
          this.$refs.fastNotAll.classList.remove('active')
        }
        if (this.isSelected != params.isSelected) {
          this.fastList = []
          // 修改 totabPage 分页插件
          this.totalPage = 999
          this.getMoreList(params)
        } else {
          this.getMoreList(params)
        }
        // 重置
        this.isSelected = params.isSelected
      })


    },
    getMoreList(params) {
      if (this.totalPage <= this.currentPage) return
      this.axios.get('/index/fast', {params}).then(res => {
        let tempList = this.fastList.slice()
        this.fastList = tempList.concat(res.page.list)
        this.currentPage = res.page.currPage
        this.totalPage = res.page.totalPage
      })
    },
    throttle(params) {
      // 防抖
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        this.getMoreList(params)
        timer = undefined;
      }, 100);

    },
    async refreshList() {
      this.$refs.refreshIcon.classList.add('fa-spin')
      this.fastList = []
      this.totalPage = 999
      let params = {
        currentPageSize: 10,
        currentPage: 1,
        isSelected: this.isSelected
      }
      await this.getMoreList(params)
      this.$refs.refreshIcon.classList.remove('fa-spin')
    },
    // 跳转详情
    skipDetails(atUuid) {
      // this.$router.push('/fast-detail/' + atUuid)
      let routeUrl = this.$router.resolve({path: '/fast-detail/' + atUuid});
      window.open(routeUrl.href, '_blank');
    },
    //图片格式转换方法
    dataURLToBlob(dataurl) {
      let arr = dataurl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime});
    },
    //点击方法
    saveImage(divText, imgText) {
      let canvasID = this.$refs[divText];
      let that = this;
      let a = document.createElement('a');
      html2canvas(canvasID).then(canvas => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = 'none';
        a.style.display = 'none';
        document.body.removeChild(dom);
        let blob = that.dataURLToBlob(dom.toDataURL('image/png'));
        a.setAttribute('href', URL.createObjectURL(blob));
        //这块是保存图片操作  可以设置保存的图片的信息
        a.setAttribute('download', imgText + '.png');
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    }
  },
  beforeDestroy() {
    /* 离开页面前销毁定时器 */
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style lang="scss" scoped>
.page_container {
  display: flex;
  position: relative;

  .list_card {
    width: 787px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px 40px;
    margin-bottom: 40px;
    letter-spacing: 1px;
    font-family: "PingFangSC-Semibold", "Helvetica Neue Bold", "Microsoft YaHei Bold", sans-serif;

    .push_card {
      height: 100px;
      background-color: #f7f5ff;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      user-select: none;

      .left {
        display: flex;

        .c_today {
          text-align: center;
          margin-right: 10px;

          span {
            font-size: 24px;
            font-weight: bold;
          }

          div {
            font-size: 14px;
          }
        }

        .number_today {
          display: flex;
          align-items: center;

          span:nth-child(odd) {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            background-color: #3e34e7;
            color: #FFFFFF;
            text-align: center;
            line-height: 40px;
            font-size: 20px;
            margin: 0 10px;
          }

          span:nth-child(even) {
            font-size: 14px;
          }
        }

        .arab_today {
          margin-left: 20px;
          line-height: 51.2px;
          font-size: 20px;
        }
      }

      button {
        padding: 8px 25px;
        font-size: 18px;
        color: #FFFFFF;
        background: #3e35e7;
        border: 1px solid #3e35e7;
        border-radius: 45px;
        cursor: pointer;
        letter-spacing: 1px;

        .fa {
          margin-right: 10px;
          //font-size: 22px;
        }
      }
    }

    .fast_card {
      margin-top: 40px;

      .tabs {
        display: flex;
        color: #3E35E7;
        font-weight: bold;
        border-bottom: 1px solid #E4E4E4;
        padding-left: 20px;

        li {
          height: 50px;
          line-height: 50px;
          position: relative;
          color: #000000;
          cursor: pointer;
          user-select: none;

          &:after {
            content: '';
            width: 0;
            position: absolute;
            bottom: -2px;
            height: 4px;
            left: 50%;
            transform: translateX(-50%);
            background: #3E35E7;
            border-radius: 8px;
            transition: all 0.3s ease-in;
          }

          &:hover {
            color: #3E35E7;

            &:after {
              width: 120%;
            }
          }
        }

        .active {
          color: #3E35E7;

          &:after {
            width: 120%;
          }
        }

        li:not(:last-child) {
          margin-right: 40px;
        }
      }

      .fast_item {
        li {
          margin-top: 50px;
          cursor: pointer;

          .title {
            color: #000000;
            font-weight: bold;
            font-size: 1rem;
            transition: color 0.15s ease-in-out;
           
          }

          .title:hover {
            color: #3E35E7;
          }

          .isSelected {
            color: #d91e18;
          }

          .tools_tip {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            padding-bottom: 15px;
            color: #9F9F9F;
            border-bottom: 1px solid #E4E4E4;

            .time {
              margin-right: 50px;
            }

            .thumbs-up {
              margin-left: 30px;
            }

            .fa {
              margin-right: 6px;
            }
          }

          .content {
            font-size: 14px;
            line-height: 1.4rem;
            margin: 10px 0;
            color: #9F9F9F;
          }
        }
      }
    }

  }

  .aside_area {
    width: 283px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;
    }

  }

  .restore-btn {
    height: 60px;
  }

  .generateImages {
    position: fixed;
    overflow-y: scroll;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    z-index: 999;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    transition: all 0.3s ease-in-out;

    .html2canvas {
      width: 375px;
      background: #FFFFFF;
      border-radius: 8px;
      transition: all 0.3s ease-in-out;

      .topCard {
        position: relative;

        .bgImg {
          width: 375px;
          height: 141px;
          object-fit: cover;
        }

        .title {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 30px;
          font-weight: bold;
          color: #3E35E7;
        }
        .link-box{
           position: absolute;
           left: 169px;
           bottom: 24px;
           font-size: 12px;
           font-family: "微软雅黑";
           font-weight: bold;
           color: white;
           letter-spacing:1px;
        }
      }

      .centerCard {
        padding: 0 24px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: #ffffff;
        letter-spacing: 1px;
        font-family: "PingFangSC-Semibold", "Helvetica Neue Bold", "Microsoft YaHei Bold", sans-serif;
        position: relative;
        top: -30px;

        .date {
          display: flex;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;
          color: #3E35E7;
          font-size: 16px;
          margin: 10px 0;
          border-bottom: 1px dashed #e0e0e0;

          .title {
            font-size: 12px;
            font-weight: bold;
            color: #3E36E7;
            margin: 22px 0 20px;
          }

          .date-hour {
            font-size: 12px;
            font-weight: 300;
            color: #999999;

            span {
              margin-left: 8px;
            }
          }
        }

        .title {
          color: #000000;
          font-size: 20px;
          margin: 26px 0 32px;
          font-family: "微软雅黑 Bold";
          letter-spacing: 1px;  // 字体间距
          line-height: 34px;
        }

        .content {
          font-size: 16px;
          line-height: 2rem;
          color: #666666;
          word-wrap:break-word;

          /deep/ img {
            max-width: 240px;
          }
        }

        .statement {
          color: #999999;
          font-size: 12px;
          margin-top: 24px;
        }
      }

      .bottomCard {
        padding: 25px 25px 39px;
        // height: 122px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        background: #f2f3f5;
        font-family: "PingFangSC-Semibold", "Helvetica Neue Bold", "Microsoft YaHei Bold", sans-serif;

        .source {
          min-height: 50px;
          width: 100%;
          position: relative;
          text-align: left;


          .laiyue {
            width: 100%;
            box-sizing: border-box;
            padding-right: 60px;
            font-size: 12px;
            font-weight: 500;
            color: #000000;
            padding-top: 10px;
            letter-spacing: 1px;  // 字体间距
            font-family:"PingFangSC-Regular, sans-serif ";//微软雅黑 Regular
            margin-left:15px ;
            .circle-small{
              padding: 0 7px;
            }
          }

          .split {
            width: 150px;
            height: 1px;
            background: #E0E0E0;
          }

          img {
            width: 70px;
            height: 70px;
            position: absolute;
            right: 0;
            top: -6px;
          }
        }

        .tip {
          font-size: 12px;
          font-family:"PingFangSC-Semibold, sans-serif ";//微软雅黑 Blod
          letter-spacing: 1px;  // 字体间距
          font-weight: 800;
          color: #000000;
          text-align: left;
          // padding-bottom: 10px;
          .circle{
            display: inline-block;
            width: 7px;
            height: 7px;
            background-color: #9894ee;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
    }

    .closeButton {
      position: relative;
      margin: 30px 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      background: #cccccc;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: none;
      border-width: 0;
      cursor: pointer;
      transition: all 0.25s ease-in-out;


      &:hover {
        background: #3e35e7;
      }

      img {
        width: 12px;
        height: 12px;
        object-fit: cover;
      }
    }
  }
}
</style>
